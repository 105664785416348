import React from 'react'
import {Link} from 'gatsby'
import PortableText from '../../serializers/portableText'
import styles from './job.module.css'
import LeftChevron from '../../../images/icons/left-chevron.svg'

const Jobs = ({PositionSummary, Responsibilities, MinimumQualifications, PreferredQualifications, AboutCompany, jobAppLink, id, slug}) => {
  return (
    <>
      <div className={styles.jobWrapper}>

        <div className={styles.btnWrapper}>
          <Link to={`/careers-es`} className={styles.moreinfoBtn}><span><LeftChevron /> Volver a trabajos</span></Link>

          <Link to={jobAppLink || `/application-employment-es?jobID=${slug}`} className={styles.applyBtn}><span>Aplicar aquí</span></Link>
        </div>

        {PositionSummary && (<div> <PortableText blocks={PositionSummary} /> </div>)}

        {Responsibilities && (<div><h3>Responsabilidades</h3> <PortableText blocks={Responsibilities} /> </div>)}

        {MinimumQualifications && (<div><h3>Cualidades mínimas</h3> <PortableText blocks={MinimumQualifications} /> </div>)}

        {PreferredQualifications && (<div><h3>Calificaciones preferidas</h3> <PortableText blocks={PreferredQualifications} /> </div>)}

        {AboutCompany && (<div><h3>Acerca de la compañía</h3> <PortableText blocks={AboutCompany} /> </div>)}

      </div>
    </>

  )
}
export default Jobs
