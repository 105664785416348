import React from 'react'
import {graphql} from 'gatsby'
import Layout from '../containers/layout'
import Page from '../components/page/jobPageEs'
import SEO from '../components/seo'

export const query = graphql`
  query($slug: String!) {
    sanityJob(slug: { current: { eq: $slug } }) {
      slug {
        current
      }
      _id
      title
      _rawPositionSummary
      _rawResponsibilities
      _rawMinimumQualifications
      _rawPreferredQualifications
      _rawAboutCompany
      jobAppLink
      hero {
        __typename
        ... on SanityMainImage {
          _key
          _type
          alt
          asset {
            fluid(maxWidth: 1900) {
              ...GatsbySanityImageFluid
            }
          }
        }
        ... on SanitySlideshow {
          _key
          _type
          slides {
            _key
            altText
            slideImage {
              asset {
                fluid(maxWidth: 1900) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
        ... on SanityVideo {
          _key
          _type
          videoType
          videoID
        }
      }
      seoSettings {
                  title
                  description
                  openGraphImage {
                    asset {
                      url
            }
         }
      }
    }
  }
`

const Job = ({data: {sanityJob: page}}) => (
  <Layout hero={page.hero} title={page.title}>
    {page.seoSettings.title && page.seoSettings.description &&
        (<SEO title={page.seoSettings.title} description={page.seoSettings.description} openGraphImage={page.seoSettings.openGraphImage ? page.seoSettings.openGraphImage.asset.url : null} />
        )}
    <Page
      title={page.title}
      PositionSummary={page._rawPositionSummary}
      Responsibilities={page._rawResponsibilities}
      MinimumQualifications={page._rawMinimumQualifications}
      PreferredQualifications={page._rawPreferredQualifications}
      AboutCompany={page._rawAboutCompany}
      jobAppLink={page.jobAppLink}
      id={page._id}
      slug={page.slug.current}
    />
  </Layout>
)

export default Job
