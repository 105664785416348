import React from 'react'

import Job from './job'
// import PortableText from '../../serializers/portableText'
import Container from '../../container'
import styles from '../page.module.css'

const JobsPage = ({title, PositionSummary, Responsibilities, MinimumQualifications, PreferredQualifications, AboutCompany, jobAppLink, id, slug}) => {
  return (
    <>
      <article className={styles.root}>
        <Container>
          <div className={styles.pageTitleWrapper}>
            <h1 className={styles.pageTitle}>{title}</h1>
          </div>

          {/* job */}
          <Job title={title}
            PositionSummary={PositionSummary}
            Responsibilities={Responsibilities}
            MinimumQualifications={MinimumQualifications}
            PreferredQualifications={PreferredQualifications}
            jobAppLink={jobAppLink}
            AboutCompany={AboutCompany} id={id} slug={slug} />

        </Container>
      </article>
    </>
  )
}
export default JobsPage
